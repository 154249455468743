import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSprout, faStar } from "@fortawesome/free-solid-svg-icons";
import '../assets/styles/Notice.css';
import guide1 from '../assets/images/guide/guide1.png';
import guide2 from '../assets/images/guide/guide2.png';
import guide3 from '../assets/images/guide/guide3.png';
import guide4 from '../assets/images/guide/guide4.png';
import guide5 from '../assets/images/guide/guide5.png';
import guide6 from '../assets/images/guide/guide6.png';
import guide7 from '../assets/images/guide/guide7.png';

function NoticePage() {
    const updatedDate = "2024-08-30";

    return (
        <div className="container mt-5">
            <div className="update-date">
                Updated: {updatedDate}
            </div>
            <div className="header">
                <FontAwesomeIcon icon={faSprout} style={{ color: 'darkgreen' }} size="xl" />
                <span className="bom">봄</span>
                <span className="ione">이오네</span>
            </div>
            <div>
                <ul className="notice-list">
                    <li className="new-notice">
                        <div className="notice-number"><FontAwesomeIcon icon={faStar}/></div>
                        <div className="notice-content">
                            <p>Chord 에 MouseOver 나 Touch 시 Image 를 확인할 수 있도록 수정하였습니다.</p>
                        </div>
                    </li>
                    <li className="new-notice">
                        <div className="notice-number"><FontAwesomeIcon icon={faStar}/></div>
                        <div className="notice-content">
                            <p>가을 테마 UI 를 추가해 두었습니다.</p>
                        </div>
                    </li>
                    <li className="new-notice">
                        <div className="notice-number"><FontAwesomeIcon icon={faStar}/></div>
                        <div className="notice-content">
                            <p>PWA(Progressive Web App) 기능을 추가하였습니다.</p>
                            <p>다운받아 설치하시면 일반 APP 처럼 사용하실 수 있습니다.</p>
                            {/*<p>(조만간 공지사항은 전체적으로 리뉴얼해 두겠습니다.)</p>*/}
                        </div>
                    </li>
                    <li className="new-notice">
                        <div className="notice-number"><FontAwesomeIcon icon={faStar}/></div>
                        <div className="notice-content">
                            <p>악보 하단에 참조음원 재생 기능 추가해 두었습니다.</p>
                            {/*<p>(ex. 주저하는 연인들을 위해 - 잔나비)</p>*/}
                            {/*<p>&nbsp;</p>*/}
                            {/*<p>악보별 데이터는 점진적으로 업데이트해 두겠습니다.</p>*/}
                            {/*<p>(가능하면 최대한 Youtube Official 음원(영상) 으로 설정해 둘 예정)</p>*/}
                        </div>
                    </li>
                    {/*<li className="new-notice">*/}
                    {/*    <div className="notice-number"><FontAwesomeIcon icon={faStar}/></div>*/}
                    {/*    <div className="notice-content">*/}
                    {/*        <p>변경된 SVG Image 데이터 기준으로 Chord 조회 메뉴를 추가하였습니다.</p>*/}
                    {/*        /!*<p>(사용자 인터페이스는 한번 더 개선 예정입니다.)</p>*!/*/}
                    {/*    </div>*/}
                    {/*</li>*/}
                    <li className="new-notice">
                        <div className="notice-number"><FontAwesomeIcon icon={faStar}/></div>
                        <div className="notice-content">
                            <p>Chord Image 포맷이 PNG 에서 SVG(Scalable Vector Graphics) 로 변경되었습니다.</p>
                            {/*<p>(Vector 이미지는 수학적 방정식으로 정의된 점/선/다각형 등을 사용하여 이미지를 구성하기 때문에, 확대하거나 축소해도 품질이 유지되는 장점이*/}
                            {/*    있습니다.)</p>*/}
                            {/*<p>최대 장점은 config 수정으로 쉽게 Chord 를 추가할 수 있다는 점 입니다.</p>*/}
                            {/*<p>누락/잘못 표기된 Chord 들은 지속적으로 업데이트 하도록 하겠습니다.</p>*/}
                        </div>
                    </li>
                    {/*<li className="new-notice">*/}
                    {/*    <div className="notice-number"><FontAwesomeIcon icon={faStar}/></div>*/}
                    {/*    <div className="notice-content">*/}
                    {/*        <p>(메뉴가 하나 추가되어) 상단 메뉴를 텍스트에서 아이콘 이미지로 변경하였습니다.</p>*/}
                    {/*    </div>*/}
                    {/*</li>*/}
                    <li>
                        <div className="notice-number">1.</div>
                        <div className="notice-content">
                            <p>Bomione 에서는 기타 및 우쿨렐레 코드악보를 제공합니다. (제가 활용하기 위해 만들었으나) 다른 필요하신 분들께도 도움이 되었으면 합니다.</p>
                        </div>
                    </li>
                    <li>
                        <div className="notice-number">2.</div>
                        <div className="notice-content">
                            <p>악보는 지속적으로 업데이트할 예정입니다. 하지만 개인적으로 활용할 악보 위주로 업데이트 될 가능성이 많기 때문에 필요한 악보는 따로 요청주시면 선반영할 수
                                있도록 하겠습니다.</p>
                            <p>참고로, 악보는 chordpro 라는 포맷으로 작성되었습니다. Global 에서 많이 사용하는 포맷이기 때문에 팝송 같은 경우는 이미 생성된 악보들이 많아
                                요청시 빠른 추가가 가능할 듯 합니다.</p>
                        </div>
                    </li>
                    <li>
                        <div className="notice-number">3.</div>
                        <div className="notice-content">
                            <p>계정등록 및 로그인은 필수는 아닙니다. 단, 로그인시 [나의 목록] 메뉴가 추가되며 악보를 [즐겨 찾기] 해둘 수 있게 됩니다.</p>
                            <p>계정등록시 개인정보는 전혀 없습니다.(ID/PW 만 입력)</p>
                        </div>
                    </li>
                    <li>
                        <div className="notice-number">4.</div>
                        <div className="notice-content">
                            <p>악보요청/기능개선/버그제보/건의사항 등 다양한 의견을 메일로 보내주시면 가능한 범위내에서 최대한 빠르게 반영해 보겠습니다.
                                (bomione.contact@gmail.com)</p>
                        </div>
                    </li>
                    <li>
                        <div className="notice-number">5.</div>
                        <div className="notice-content">
                            <p>기본 사용 방법</p>
                            <img src={guide1} alt="가이드 1"/>
                            <img src={guide2} alt="가이드 2"/>
                            <img src={guide3} alt="가이드 3"/>
                            <img src={guide4} alt="가이드 4"/>
                        </div>
                    </li>
                    <li>
                        <div className="notice-number">6.</div>
                        <div className="notice-content">
                            <p>나의 목록 관리</p>
                            <img src={guide5} alt="가이드 5"/>
                            <img src={guide6} alt="가이드 6"/>
                            <img src={guide7} alt="가이드 7"/>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default NoticePage;
