import { faSun, faMoon, faTree, faSnowflake } from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export enum ThemeType {
    LIGHT = 'light',
    DARK = 'dark',
    AUTUMN = 'autumn',
    //WINTER = 'winter',
}

/**
 * Button > variant
 *
 * primary: 파란색 (#0d6efd)
 * secondary: 회색 (#6c757d)
 * success: 초록색 (#198754)
 * danger: 빨간색 (#dc3545)
 * warning: 노란색 (#ffc107)
 * info: 하늘색 (#0dcaf0)
 * light: 밝은 회색 (#f8f9fa)
 * dark: 검정색 (#212529)
 * link: 파란색 텍스트 (#0d6efd)
 */
interface ThemeConfig {
    variant: string;
    icon: IconDefinition;
}

export const Themes: { [key in ThemeType]: ThemeConfig } = {
    [ThemeType.LIGHT]: {
        variant: 'primary',
        icon: faSun,
    },
    [ThemeType.DARK]: {
        variant: 'secondary',
        icon: faMoon,
    },
    [ThemeType.AUTUMN]: {
        variant: 'warning',
        icon: faTree,
    },
    // [ThemeType.WINTER]: {
    //     variant: 'danger',
    //     icon: faSnowflake,
    // },
};

export const DEFAULT_THEME_TYPE = ThemeType.AUTUMN;
